import config from '../config';
import {mergeMeta, fetchJsonData} from '../utils/fetch';
import {snakeToCamelObj} from '../utils/string';
import { create } from 'zustand'

export const useCaseStudiesStore = create((set) => ({
    caseStudies: [],
    setCaseStudies: (caseStudies) => set((state) => ({ caseStudies })),
}))

export const transformCaseStudies = (caseStudies) => {
    caseStudies = caseStudies.map((t) => {
        mergeMeta(t);
        t = snakeToCamelObj(t);
        t.url = `/case-study/${t.id}`;
        return t;
    })
    return caseStudies
}

export const getCaseStudies = async () => {
    let caseStudies = await fetchJsonData(`${config.API.DERIVED_PATH}/case-studies.json`)
    return transformCaseStudies(caseStudies)
}

export async function initCaseStudies() {
    // now load from s3 incase the user wants to use the rest of the website!
    let caseStudies = await getCaseStudies()
    useCaseStudiesStore.getState().setCaseStudies(caseStudies)
}
