import config from '../config';
import {mergeMeta, fetchJsonData} from '../utils/fetch';
import {snakeToCamelObj} from '../utils/string';
import { create } from 'zustand'

export const useNewsStore = create((set) => ({
    news: [],
    setNews: (news) => set((state) => ({ news })),
}))

export const transformNews = (news) => {
    news = news.map((t) => {
        mergeMeta(t);
        t = snakeToCamelObj(t);
        t.url = `/news/${t.id}`;
        return t;
    })
    return news
}

export const getNews = async () => {
    let news = await fetchJsonData(`${config.API.DERIVED_PATH}/news.json`)
    return transformNews(news)
}

export async function initNews() {
    // now load from s3 incase the user wants to use the rest of the website!
    let news = await getNews()
    useNewsStore.getState().setNews(news)
}